<template>
  <div id="today_page">
    <div class="container">
      <div v-if="false">
        <div class="base-alert base-alert-error" v-if="!userClaims.email_verified || !userClaims.phone_verified || !userClaims.photo_uploaded">
          <!-- If email or phone are not verified -->
          <span v-if="!userClaims.email_verified || !userClaims.phone_verified">Your profile isn't verified, <router-link to="/profile-verification?verifications=EMAIL,PHONE,PHOTO&direct=true">click here</router-link> to complete it.</span>
          <!-- If only photo no uploaded -->
          <span v-else>You don't have a profile photo, <router-link to="/profile-verification?verifications=PHOTO&direct=true">click here</router-link> to upload one.</span>
        </div>
      </div>

      <!-- Weather icon and summary -->
      <div v-if="show_weather" class="weather-info">
        <div>
          <div class="weather-left">
            <img :src="weather_icon" alt="">
          </div>
        </div>
        <div>
          <div class="weather-right">
            <span class="today-temperature">{{ Math.round(this.weather_data.currently.temperature || 0) }}°C</span>
            <span class="today-summary">{{ weather_data.hourly.summary || 'Loading...' }}</span>
          </div>
        </div>
      </div>

      <hr v-if="show_weather" style="margin-bottom: 15px" />

      <!-- Weather stats and hourly breakdown -->
      <div v-if="show_weather" class="weather-stats">
        <div id="today_weather_other_container">
          <div>
            <span>Humidity</span>
            <span>{{ Math.round(weather_data.currently.humidity * 100) || 0 }}%</span>
          </div>

          <div>
            <span>Rain</span>
            <span>{{ Math.round(weather_data.currently.precipProbability * 100) || 0 }}%</span>
          </div>

          <div>
            <span>Wind</span>
            <span>{{ Math.round(weather_data.currently.windSpeed) || 0 }}<span style="font-size: 12px">km/h</span></span>
          </div>

          <div class="scroll_arrow">
            <font-awesome-icon
                style="font-size: 14px"
                :icon="['fas', 'chevron-right']"
              />
          </div>
        </div>

        <!-- Hourly weather -->
        <div id="today_weather_other_daily">
          <div :key="item.time" v-for="item in weather_data.hourly.data.slice(0, 12)">
            <div class="today_weather_daily_image">
              <img :src="getWeatherIcon(item.icon)" alt="">
            </div>
            <span>{{ getFormattedTime(item.time) }}</span>
          </div>
          
        </div>
      </div>

      <hr v-if="show_weather" style="margin-top: 10px;" />

      <span class="small-title">Your mobility ID</span>

      <div style="display: flex; justify-content: center;">
        <div class="base-card accepted-glowing" style="max-width: 300px; padding: 25px;">
          <img style="width: 100%;" :src="`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${$store.getters.user.id}`" alt="">
          <span style="display: block; margin-top: 5px; font-style: italic; text-align: center; margin-bottom: -10px;">Scan this pass to access any transport mode.</span>
        </div>
      </div>

      <base-spacer space="20px"></base-spacer>

      <span class="small-title">Upcoming trips</span>

      <div class="base-horizontal-container">
        <div @click="$router.push('/shuttle-live-info/sdf')" class="base-card shadow-hover horizontal-item upcoming-trip-card" style="width: 250px; padding: 15px;">
          <img :src="publicPath + 'img/activity-icons/public_transport_black.png'">
          <div>
            <h5>City Center Shuttle</h5>
            <p class="soon-arriving">3 min left</p>
          </div>
        </div>

        <div @click="$router.push('/shuttle-live-info/sdf')" class="base-card shadow-hover horizontal-item upcoming-trip-card" style="width: 250px; padding: 15px;">
          <img :src="publicPath + 'img/activity-icons/bus_white.png'">
          <div>
            <h5>Greystones Shuttle</h5>
            <p>08:30 - 10th Aug</p>
          </div>
        </div>

        <div @click="$router.push('/shuttle-live-info/sdf')" class="base-card shadow-hover horizontal-item upcoming-trip-card" style="width: 250px; padding: 15px;">
          <img :src="publicPath + 'img/activity-icons/bus_white.png'">
          <div>
            <h5>Greystones Shuttle</h5>
            <p>16:45 - 10th Aug</p>
          </div>
        </div>
      </div>
      <a style="display: block; text-align: right; margin-top: 5px;" class="highlighted" @click="$router.push('/schedule')">see all trips</a>

      <!-- <div class="today-schedule">
        <div v-if="schedule && schedule.type.split('.')[0] == 'carpooling'" class="today-schedule-carpooling">
          <el-card style="margin-bottom: 10px" :body-style="{'padding': '15px'}" v-for="carpooler in schedule.options" :key="carpooler.user">
            <div class="today-schedule-carpooling-info user-card-body">
              <div style="height: 56px">
                <el-avatar @click.native="$router.push(`/carpooling-profile/${carpooler.user}`)" :size="56" :src="getUserPhoto(carpooler.user, '128')"></el-avatar>
              </div>
              <div class="user-card-info">
                <span>{{ carpooler.display_name }}</span>
                <span>{{ direction == 'go' ? 'To college' : 'Back home' }}</span>
              </div>
            </div>

            <div class="today-schedule-chat">
              <el-input v-model="to_send_message" placeholder="Type a message"></el-input>
              <el-button @click="sendWhatsapp(carpooler.phone_number)" type="success" icon="el-icon-s-promotion" circle></el-button>
            </div>
          </el-card>
        </div>

        <div v-if="!schedule" class="today-schedule-empty">
          <el-card v-if="userData.carpooling_details && userData.carpooling_details.available">
            <div style="text-align: center">
              <img :src="publicPath + 'img/idea-globe.svg'" style="max-width: 120px">
            </div>
            <span style="display: block; text-align: center; margin-top: 7px">You have nothing scheduled today. <router-link class="highlighted" to="/search">Click here</router-link> to find carpooling partners.</span>
          </el-card>

          <el-card v-else>
            <div style="text-align: center">
              <img :src="publicPath + 'img/idea-friends.svg'" style="max-width: 120px">
            </div>

            <span style="display: block; text-align: center; margin-top: 7px">Your profile is set to <i>not available</i>.</span>
            <span style="display: block; text-align: center; margin-top: 7px">To allow people to send you requests, set yourself as available <router-link class="highlighted" to="/settings/carpooling-info">here</router-link>.</span>
          </el-card>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';
import moment from 'moment';

let weather_icon_images = {
  "clear-day": "Sun.png",
  "clear-night": "Moon.png",
  rain: "Rainy.png",
  snow: "Snow.png",
  sleet: "Rainy.png",
  wind: "Cloud-wind.png",
  fog: "Closet.png",
  cloudy: "Closet.png",
  "partly-cloudy-day": "Sunshine.png",
  "partly-cloudy-night": "Night.png",
  hail: "Snow.png",
  thunderstorm: "Rainy-thunder.png",
  tornado: "tonado.png"
};

export default {
    data() {
      return {
        weather_data: {
          currently: {},
          hourly: {
            data: []
          }
        },
        show_weather: false,
        to_send_message: '',
        weather_label: '',
        publicPath: process.env.BASE_URL
      }
    },

    async mounted() {
      this.setWeatherData(await localforage.getItem('weatherData'));

      // Refresh token to get new claims
      this.$store.dispatch('checkUserClaims', true);

      // Request weather data
      if (this.show_weather) {
        let _data = await this.SharoAPI({ action: 'corsAnywhere', data: `https://api.darksky.net/forecast/aab12243b64c15175ecab309aa195fae/${53.3498},${-6.2603}?units=si&exclude=minutely,daily,alerts,flags` });
  
        localforage.setItem('weatherData', _data.data.result);
        this.setWeatherData(_data.data.result);
      }
    },

    methods: {
      setWeatherData(data) {
        this.weather_data = !data ? { hourly: { data: [] }, currently: {}} : data;
        this.weather_label = weather_icon_images[this.weather_data.currently.icon];
      },

      getWeatherIcon(icon) {
        return `${process.env.BASE_URL}img/weather-icons/${weather_icon_images[icon]}`
      },

      getFormattedTime(time) {
        return moment(time * 1000).format('HH:mm');
      },
      
      datetime(time) {
        return moment(time).format('HH:mm');
      }
    },

    computed: {
      userData() {
        return this.$store.getters.user.data;  
      },
      
      weather_icon() {
        return `${process.env.BASE_URL}img/weather-icons/${this.weather_label}`;
      },

      direction() {
        return moment().hour() < 12 ? 'go' : 'back';
      },

      schedule() {
        return this.$store.getters.userSchedule && this.$store.getters.userSchedule[moment().format('YYYY-MM-DD')] && this.$store.getters.userSchedule[moment().format('YYYY-MM-DD')][this.direction];
      },

      userClaims() {
        return this.$store.getters.userClaims || {};
      },

      ip() {
        return this.$store.getters.ip_information;
      }
    }

}
</script>

<style lang="scss" scoped>
  @import "today.scss";

  .container {
    max-width: 600px;
  }

  .full-tabs {
    margin-top: 15px;
  }

  .upcoming-trip-card {
    display: flex;
    align-items: center;

    img {
      width: 50px;
    }

    div {
      margin-left: 10px;
    }

    p.soon-arriving {
      color: #EB4E3F;
      font-weight: 400;
    }
  }

  @keyframes acceptedGlowing {
    0% {
      border: 1px solid #EBEEF5;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    }

    25% {
      border: 1px solid rgb(103, 194, 58);
      box-shadow: 0 2px 12px 0 rgba(103, 194, 58, .5);
    }

    50% {
      border: 1px solid #EBEEF5;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    }

    75% {
      border: 1px solid rgb(103, 194, 58);
      box-shadow: 0 2px 12px 0 rgba(103, 194, 58, .5);
    }
  }

  
  .accepted-glowing {
    animation: acceptedGlowing 2s ease-in-out 3s 2;
  }
</style>