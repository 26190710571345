var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "today_page" } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        false
          ? _c("div", [
              !_vm.userClaims.email_verified ||
              !_vm.userClaims.phone_verified ||
              !_vm.userClaims.photo_uploaded
                ? _c("div", { staticClass: "base-alert base-alert-error" }, [
                    !_vm.userClaims.email_verified ||
                    !_vm.userClaims.phone_verified
                      ? _c(
                          "span",
                          [
                            _vm._v("Your profile isn't verified, "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/profile-verification?verifications=EMAIL,PHONE,PHOTO&direct=true"
                                }
                              },
                              [_vm._v("click here")]
                            ),
                            _vm._v(" to complete it.")
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _vm._v("You don't have a profile photo, "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/profile-verification?verifications=PHOTO&direct=true"
                                }
                              },
                              [_vm._v("click here")]
                            ),
                            _vm._v(" to upload one.")
                          ],
                          1
                        )
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm.show_weather
          ? _c("div", { staticClass: "weather-info" }, [
              _c("div", [
                _c("div", { staticClass: "weather-left" }, [
                  _c("img", { attrs: { src: _vm.weather_icon, alt: "" } })
                ])
              ]),
              _c("div", [
                _c("div", { staticClass: "weather-right" }, [
                  _c("span", { staticClass: "today-temperature" }, [
                    _vm._v(
                      _vm._s(
                        Math.round(this.weather_data.currently.temperature || 0)
                      ) + "°C"
                    )
                  ]),
                  _c("span", { staticClass: "today-summary" }, [
                    _vm._v(
                      _vm._s(_vm.weather_data.hourly.summary || "Loading...")
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm.show_weather
          ? _c("hr", { staticStyle: { "margin-bottom": "15px" } })
          : _vm._e(),
        _vm.show_weather
          ? _c("div", { staticClass: "weather-stats" }, [
              _c("div", { attrs: { id: "today_weather_other_container" } }, [
                _c("div", [
                  _c("span", [_vm._v("Humidity")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        Math.round(_vm.weather_data.currently.humidity * 100) ||
                          0
                      ) + "%"
                    )
                  ])
                ]),
                _c("div", [
                  _c("span", [_vm._v("Rain")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        Math.round(
                          _vm.weather_data.currently.precipProbability * 100
                        ) || 0
                      ) + "%"
                    )
                  ])
                ]),
                _c("div", [
                  _c("span", [_vm._v("Wind")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        Math.round(_vm.weather_data.currently.windSpeed) || 0
                      )
                    ),
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v("km/h")
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "scroll_arrow" },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { "font-size": "14px" },
                      attrs: { icon: ["fas", "chevron-right"] }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { attrs: { id: "today_weather_other_daily" } },
                _vm._l(_vm.weather_data.hourly.data.slice(0, 12), function(
                  item
                ) {
                  return _c("div", { key: item.time }, [
                    _c("div", { staticClass: "today_weather_daily_image" }, [
                      _c("img", {
                        attrs: { src: _vm.getWeatherIcon(item.icon), alt: "" }
                      })
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.getFormattedTime(item.time)))
                    ])
                  ])
                }),
                0
              )
            ])
          : _vm._e(),
        _vm.show_weather
          ? _c("hr", { staticStyle: { "margin-top": "10px" } })
          : _vm._e(),
        _c("span", { staticClass: "small-title" }, [
          _vm._v("Your mobility ID")
        ]),
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "center" } },
          [
            _c(
              "div",
              {
                staticClass: "base-card accepted-glowing",
                staticStyle: { "max-width": "300px", padding: "25px" }
              },
              [
                _c("img", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    src:
                      "https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=" +
                      _vm.$store.getters.user.id,
                    alt: ""
                  }
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      display: "block",
                      "margin-top": "5px",
                      "font-style": "italic",
                      "text-align": "center",
                      "margin-bottom": "-10px"
                    }
                  },
                  [_vm._v("Scan this pass to access any transport mode.")]
                )
              ]
            )
          ]
        ),
        _c("base-spacer", { attrs: { space: "20px" } }),
        _c("span", { staticClass: "small-title" }, [_vm._v("Upcoming trips")]),
        _c("div", { staticClass: "base-horizontal-container" }, [
          _c(
            "div",
            {
              staticClass:
                "base-card shadow-hover horizontal-item upcoming-trip-card",
              staticStyle: { width: "250px", padding: "15px" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/shuttle-live-info/sdf")
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src:
                    _vm.publicPath +
                    "img/activity-icons/public_transport_black.png"
                }
              }),
              _vm._m(0)
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "base-card shadow-hover horizontal-item upcoming-trip-card",
              staticStyle: { width: "250px", padding: "15px" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/shuttle-live-info/sdf")
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.publicPath + "img/activity-icons/bus_white.png"
                }
              }),
              _vm._m(1)
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "base-card shadow-hover horizontal-item upcoming-trip-card",
              staticStyle: { width: "250px", padding: "15px" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/shuttle-live-info/sdf")
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.publicPath + "img/activity-icons/bus_white.png"
                }
              }),
              _vm._m(2)
            ]
          )
        ]),
        _c(
          "a",
          {
            staticClass: "highlighted",
            staticStyle: {
              display: "block",
              "text-align": "right",
              "margin-top": "5px"
            },
            on: {
              click: function($event) {
                return _vm.$router.push("/schedule")
              }
            }
          },
          [_vm._v("see all trips")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h5", [_vm._v("City Center Shuttle")]),
      _c("p", { staticClass: "soon-arriving" }, [_vm._v("3 min left")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h5", [_vm._v("Greystones Shuttle")]),
      _c("p", [_vm._v("08:30 - 10th Aug")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h5", [_vm._v("Greystones Shuttle")]),
      _c("p", [_vm._v("16:45 - 10th Aug")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }